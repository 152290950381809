<template>
	<div style="border: 1px solid #ccc;padding:15px;background-color: #f5f5f5;">
		<div style="padding: 15px;background-color: #fff;" class="boxnumber">
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">标题：</label>
				<el-input placeholder="请输入内容" style="width:90%;" v-model="newsdata.title"></el-input>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">副标题：</label>
				<el-input placeholder="请输入内容" style="width:90%;" v-model="newsdata.description"></el-input>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:240px;">图片：</label>
				<el-upload class="avatar-uploader"
					style="border: 1px solid #ebebeb;margin: 20px;padding: 5px;border-radius:15px;"
					:show-file-list="false" :action="$ips+'/common/upfile'" 
					:on-success="upload">
					<img v-if="newsdata.imgurl" :src="newsdata.imgurl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-button type="primary" style="margin: 15px 0;" @click="updatanews">编辑轮播图</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue'
	export default Vue.extend({
		data() {
			return {
				newsdata: {
					title: '',
					description: '',
					updatatime: '',
					imgurl: '',
				}
			}
		},
		methods: {
			updatanews() {
				//  编辑新闻
				console.log(this.newsdata);
				if (this.newsdata.title == "" || this.newsdata.description == '') {
					this.$message({
						showClose: true,
						message: '请填写全部内容',
						type: 'error'
					});
				} else if (this.newsdata.imgurl == '') {
					// 未上传图片
					this.$message({
						showClose: true,
						message: '请上传图片',
						type: 'error'
					});
				} else {
					let that = this
					this.$confirm('确定要编辑吗', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then((res1) => {
						console.log(res1);
						if(res1 == 'confirm'){
							console.log('确认');
							that.newsdata.updatatime = that.$gettime()
							that.$post("/index/updatakronoswiper",that.newsdata).then(res=>{
								if(res.data=='编辑成功'){
									that.$message({
										type: 'success',
										message: '编辑成功!'
									});
								}else{
									that.$message({
										type: 'error',
										message: '编辑失败!'
									});
								}
							})
						}else{
							console.log('取消');
							this.$message({
								type: 'info',
								message: '已取消'
							});
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}
			},
			upload(e) {
				console.log(e);
				if (e.data.url) {
					this.newsdata.imgurl = e.data.url
				}
			}
		},
		mounted() {
			this.$get('/index/getkronoswiper',{
				id:this.$route.params.id
			}).then(res=>{
				this.newsdata = res.data[0]
			})
		}
	})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #a5a5a5;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.boxnumber div{
		margin: 10px auto;
		
	}
	.boxnumber div label{
		width:60px;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>